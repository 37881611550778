import { IKeHoachSuaChuaResponseBase } from './../interfaces/ApiResponses';
import {
  ApiResponse,
  PaginationResponseBase,
} from '@/core/interfaces/ApiResponses';
import { apiServiceInstance } from '@/core/helpers/utils';
import { IDanhSachKeHoachFilterParams } from '../interfaces/ApiRequest.interface';

export interface ICreateKeHoachRequest {
  ten_ke_hoach: string;
  thang: number;
  ghi_chu: string;
  id_danh_muc_toa_xe: number;
  id_cap_sc: number;
  so_luong_toa_xe: number;
  isCreating: boolean;
  id_ke_hoach?: number;
}

export class KeHoachSuaChuaService {
  static get(id: number): Promise<{
    data: ApiResponse<IKeHoachSuaChuaResponseBase>;
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/ke-hoach/chi-tiet/${id}`
    );
  }

  static list({
    current_page,
    per_page,
    s,
  }: IDanhSachKeHoachFilterParams = {
    current_page: 1,
    per_page: 200,
    s: '',
  }): Promise<{
    data: ApiResponse<PaginationResponseBase<IKeHoachSuaChuaResponseBase>>
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      '/api/v1/ke-hoach/danh-sach',
      {
        params: {
          ...(current_page && {
            page: current_page,
          }),
          ...(s && { s }),
        },
        headers: {
          ...(per_page && {
            perpage: per_page,
          }),
        }
      }
    )
  }

  static createOrUpdate({
    ten_ke_hoach,
    thang,
    ghi_chu,
    id_danh_muc_toa_xe,
    id_cap_sc,
    so_luong_toa_xe,
    isCreating,
    id_ke_hoach,
  }: ICreateKeHoachRequest): Promise<{
    data: ApiResponse<IKeHoachSuaChuaResponseBase>;
  }> {
    apiServiceInstance().vueInstance.axios.defaults.headers.common[
      'Content-Type'
    ] = 'multipart/form-data';
    const bodyFormData = new FormData();
    bodyFormData.append('keHoach[ten_ke_hoach]', ten_ke_hoach);
    bodyFormData.append('keHoach[thang]', thang.toString());
    bodyFormData.append('keHoach[so_luong_toa_xe]', so_luong_toa_xe.toString());
    bodyFormData.append('keHoach[ghi_chu]', ghi_chu);
    bodyFormData.append('keHoach[id_danh_muc_toa_xe]', id_danh_muc_toa_xe.toString());
    bodyFormData.append('keHoach[id_cap_sc]', id_cap_sc.toString());

    const URI = isCreating ? '/api/v1/ke-hoach/tao-moi' : `/api/v1/ke-hoach/cap-nhat/${id_ke_hoach}`;

    return apiServiceInstance().vueInstance.axios.post(
      URI,
      bodyFormData
    );
  }
}
