
import { defineComponent, PropType } from 'vue';
import { useDateTime } from '@/core/hooks/use-date-time-hook';
import { IVatTuDinhMucCoDinh } from '@/core/interfaces/ApiResponses';
import { formatCurrencyAndQuantity } from '@/core/helpers/utils';
import { useButtonCustom } from '@/core/hooks/use-button-custom';
import { useRouterCustom } from '@/core/hooks/use-router-custom';

import WordBreakColumn from '../../shared/work-break-column/WordBreakColumn.vue';
import ButtonCustom from '../../shared/buttons/ButtonCustom.vue';

export default defineComponent({
  name: 'dinh-muc-co-dinh-vat-tu-table',

  emits: ['remove'],

  components: { ButtonCustom, WordBreakColumn },

  data() {
    return {
      vatTuItems: this.items as IVatTuDinhMucCoDinh[],
    }
  },

  setup() {
    const { formatDisplayingDate } = useDateTime();
    const { ButtonTypeColors, ButtonsType } = useButtonCustom();
    const { push } = useRouterCustom();

    return {
      formatDisplayingDate,
      push,
      ButtonTypeColors, ButtonsType,
    }
  },

  props: {
    items: {
      type: Array as PropType<Array<IVatTuDinhMucCoDinh>>,
      default: () => ([]),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showPriceAndTotal: {
      type: Boolean,
      default: false,
    },
    showDeleteButton: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
		items(value) {
			this.vatTuItems = value;
		},
	},

  methods: {
    formatCurrencyAndQuantity,

    thanhTienDisplay(index) {
      return formatCurrencyAndQuantity(
        this.vatTuItems[index].don_gia * this.items[index].pivot.so_luong,
        false,
      );
    },
  }
})
