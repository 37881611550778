
import { defineComponent } from 'vue';
import { setCurrentPageTitle } from '@/core/helpers/breadcrumb';
import { getErrorMsg, swalNotification } from '@/core/helpers/utils';
import { useLoading } from '@/core/hooks/use-loading';
import { useRouterCustom } from '@/core/hooks/use-router-custom';
import { IBienBanGiaiTheResponseBase, IKeHoachSuaChuaResponseBase } from '@/core/interfaces/ApiResponses';
import { KeHoachSuaChuaService } from '@/core/services/KeHoachSuaChua.service';
import { useDateTime } from '@/core/hooks/use-date-time-hook';
import { useButtonCustom } from '@/core/hooks/use-button-custom';
import { usePagination } from '@/core/hooks/use-pagination';
import { BienBanGiaiTheService } from '@/core/services/BienBanGiaiThe.service';

import BienBanGiaiTheTable from '@/components/shared/bien-ban-giai-the-table/BienBanGiaiTheTable.vue';
import ConfirmHoanThanh
  from "@/views/crafted/pages/bien-ban-giai-the/confirm-hoan-thanh-bien-ban-modal/ConfirmHoanThanh.vue";
import PageRowLayout from '@/layout/_shared/PageRowLayout.vue';
import DivCol from '@/layout/_shared/DivCol.vue';
import DivRow from '@/layout/_shared/DivRow.vue';
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import PerPageSelection from '@/components/shared/per-page-selection/PerPageSelection.vue';

import Pagination from '@/components/shared/pagination/Pagination.vue';
import DinhMucCoDinhSection from '@/components/dinh-muc-co-dinh-section/DinhMucCoDinhSection.vue';

export default defineComponent({
  name: 'chi-tiet-ke-hoach-sua-chua',

  components: {
    BienBanGiaiTheTable,
    ButtonCustom,
    ConfirmHoanThanh,
    DivCol,
    DivRow,
    PerPageSelection,
    Pagination,
    PageRowLayout,
    DinhMucCoDinhSection,
  },

  setup() {
    const { goBack, push, id: idKeHoach } = useRouterCustom();
    const { ButtonTypeColors, ButtonsType } = useButtonCustom()
    const { startLoading, endLoading } = useLoading();
    const { currentPage, totalItems, perPage, perPageArr } = usePagination();
    const { moment } = useDateTime();

    return {
      goBack, push,
      idKeHoach,
      startLoading, endLoading,
      ButtonTypeColors, ButtonsType,
      currentPage, totalItems, perPage, perPageArr,
      moment,
    }
  },

  data() {
    return {
      bienBanItems: [] as IBienBanGiaiTheResponseBase[],
      confirmHoanThanhBienBan: false,
      keHoachDetail: null as IKeHoachSuaChuaResponseBase | null,
      selectingBienBan: null as IBienBanGiaiTheResponseBase | null,
    };
  },

  async mounted() {
    setCurrentPageTitle('Kế hoạch sửa chữa');
    await this.fetchData();
    this.fetchBienBan();
  },

  watch: {
		async currentPage() {
			await this.fetchBienBan();
		},

		async perPage() {
			await this.fetchBienBan();
		}
	},

  methods: {
    async fetchData() {
      try {
        this.startLoading();
        const { data: { data } } = await KeHoachSuaChuaService.get(this.idKeHoach);
        this.keHoachDetail = data;
        this.endLoading();
      } catch(e) {
        this.endLoading();
          swalNotification(
          getErrorMsg(e, 'Có lỗi xảy ra'),
          'error'
        );
      }
    },

    async fetchBienBan() {
      try {
        this.startLoading();
        const { data: { data: { data } } } = await BienBanGiaiTheService.list({
          current_page: this.currentPage,
          per_page: this.perPage,
          id_ke_hoach_sc: this.idKeHoach,
        });
        this.bienBanItems = data;
        this.endLoading();
      } catch(e) {
        this.endLoading();
        await swalNotification(
          getErrorMsg(e, 'Có lỗi xảy ra'),
          'error'
        );
      }
    },
    selectBienBanHandler({ id: selectedBienBanId }: { id: string }) {
      const foundBienBan = this.bienBanItems.find(({ id: idBienBan }) => selectedBienBanId === idBienBan);

      if (foundBienBan) {
        this.selectingBienBan = foundBienBan;

        this.confirmHoanThanhBienBan = true;
      }
    },
    async hoanThanhHander() {
      this.confirmHoanThanhBienBan = false;

      await this.fetchBienBan();
    },
  }
})
