
import { defineComponent } from 'vue';
import { swalErrNotification } from '../../core/helpers/utils';
import { IDinhMucCoDinhNewResponseBase } from '../../core/interfaces/ApiResponses';
import { DinhMucCoDinhService } from '../../core/services/DinhMucCoDinh.service';
import { useDateTime } from '../../core/hooks/use-date-time-hook';
import { useButtonCustom } from '../../core/hooks/use-button-custom';

import PageRowLayout from '../../layout/_shared/PageRowLayout.vue';
import DinhMucCoDinhVatTuTable from './dinh-muc-co-dinh-table/DinhMucCoDinhVatTuTable.vue';
import DivRow from '../../layout/_shared/DivRow.vue';
import DivCol from '../../layout/_shared/DivCol.vue';
import LabelCustom from '../shared/label-custom/LabelCustom.vue';
import ButtonCustom from '../shared/buttons/ButtonCustom.vue';

export default defineComponent({
  name: 'dinh-muc-co-dinh-section',

  emits: ['on-done-loading'],

  components: {
    ButtonCustom,
    DinhMucCoDinhVatTuTable,
    DivCol,
    DivRow,
    LabelCustom,
    PageRowLayout,
  },

  setup() {
    const { formatDisplayingDate } = useDateTime();
    const { ButtonTypeColors, ButtonsType } = useButtonCustom()

    return {
      ButtonTypeColors, ButtonsType,
      formatDisplayingDate,
    }
  },

  props: {
    createPhieuXuatShow: {
      type: Boolean,
      default: true,
    },
    idBienBan: {
      type: Number,
      default: 0,
    },
    idCapSuaChua: {
      type: Number,
      required: true,
    },
    idDanhMucToaXe: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      dinhMucCoDinhDetail: null as null | IDinhMucCoDinhNewResponseBase,
    }
  },

  mounted() {
    this.fetchData();
  },

  watch: {
    idCapSuaChua(_value) {
      this.fetchData();
    },

    idDanhMucToaXe(_value) {
      this.fetchData();
    },
  },

  methods: {
    async fetchData() {
      try {
        // TODO: get data with params later
        const { data: { data: { data } } } = await DinhMucCoDinhService.getList({
          id_cap_sc: this.idCapSuaChua,
          id_dm_toa_xe: this.idDanhMucToaXe,
        });

        this.dinhMucCoDinhDetail= data[0];

        this.$emit('on-done-loading');
      } catch(e) {
        this.$emit('on-done-loading');

        await swalErrNotification(e, 'Có lỗi xảy ra khi lấy thông tin định mức cố định');
      }
    }
  }
})
